@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .social-icon {
    @apply flex items-center text-sm transition duration-200 sm:text-lg;
  }

  .link {
    @apply transition hover:opacity-75;
  }
}

.slider {
  @apply pointer-events-none flex w-full items-center overflow-hidden;

  max-width: 100vw;
}

.slider-items {
  @apply flex items-center space-x-4 text-tattoo;

  animation: slideshow 13s linear infinite;
}

.slider-items > div {
  @apply flex animate-pulse items-center text-4xl;
}

.slider-items > div::after {
  @apply ml-4 inline-block h-2 w-2 rounded-full bg-tattoo;

  content: '';
}

@keyframes slideshow {
  from {
    transform: translateX(0);
  }

  to {
    /* The exact width of 1 set of slides, for now 100px each additional */
    transform: translateX(-1075px);
  }
}
